import React from "react";
import Page from "../components/layout/page";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import { useRouter } from "next/router";

export default function Torgid404() {
    const router = useRouter();
    React.useEffect(() => {
        ReactGA_UA.pageview(router.asPath);
        ReactGA_4.send({ hitType: "pageview", page: router.asPath });
    });
    return (
        <Page title="404 - Síða fannst ekki"/>
    );
}
